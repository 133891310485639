<template>
  <div>
    <h4>Toplam Satılan Ürünler</h4>
    <p>Her günün raporu bir sonraki gün 08:00'da oluşur.</p>

    <v-row style="align-items: center;">
      <v-col>
        <v-text-field
          label="Başlangıç Tarihi"
          type="date"
          v-model="startDate"
          :min="startDateLimit"
          :max="yesterdayDate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Bitiş Tarihi"
          type="date"
          v-model="endDate"
          :min="startDateLimit"
          :max="yesterdayDate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn style="margin-left: 8px;" @click="filterByDateRange" class="custom-btn" elevation="2">
          <v-icon left>mdi-filter</v-icon> Tarih Aralığını Uygula
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="noData" type="info" class="mt-1">
      Seçilen tarih aralığında kayıt bulunmamaktadır.
    </v-alert>

    <v-data-table
      v-else
      :headers="headers"
      :items="sortedProductData"
      item-key="productName"
      class="mt-1"
      :items-per-page="100"
      
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.productName }}</td>
          <td>{{ item.quantity }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      orders: [],
      filteredOrders: [],
      startDate: '',
      endDate: '',
      noData: false,
      startDateLimit: '2024-07-15',
      yesterdayDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      aggregatedProductData: {},
      sortOrder: 'productNameAsc',
      headers: [
        { text: 'Ürün Adı', value: 'productName' },
        { text: 'Toplam Adet', value: 'quantity' }
      ]
    };
  },
  computed: {
    sortedProductData() {
      const productArray = Object.entries(this.aggregatedProductData).map(([productName, quantity]) => ({
        productName,
        quantity
      }));

      productArray.sort((a, b) => {
        switch (this.sortOrder) {
          case 'productNameAsc':
            return a.productName.localeCompare(b.productName);
          case 'productNameDesc':
            return b.productName.localeCompare(a.productName);
          case 'quantityAsc':
            return a.quantity - b.quantity;
          case 'quantityDesc':
            return b.quantity - a.quantity;
          default:
            return 0;
        }
      });

      return productArray;
    }
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      try {
        const response = await axios.get('https://kitpanel-33ba2-default-rtdb.firebaseio.com/ty_data.json');
        this.orders = Object.values(response.data).map(order => {
          return {
            ...order,
            orderDate: dayjs(order.orderDate).subtract(3, 'hour').valueOf(),
            lines: order.lines.map(line => ({
              ...line,
              cost: 0,
              cargo: 0,
              comission: 0
            }))
          };
        });
      } catch (error) {
        console.error("API'dan veri çekilirken hata oluştu:", error);
      }
    },
    filterByDateRange() {
      const start = this.startDate ? dayjs(this.startDate).startOf('day').valueOf() : null;
      const end = this.endDate ? dayjs(this.endDate).endOf('day').valueOf() : null;

      if (!start || !end) {
        alert('Geçerli bir tarih aralığı girin.');
        return;
      }

      const today = dayjs().startOf('day').valueOf();
      if (end === today) {
        alert('Henüz rapor oluşmadı.');
        return;
      }

      if (start < dayjs(this.startDateLimit).startOf('day').valueOf() || end < dayjs(this.startDateLimit).startOf('day').valueOf()) {
        alert('Başlangıç tarihi 14.07.2024 tarihinden önce olamaz.');
        return;
      }

      this.filteredOrders = this.orders.filter(order => {
        return order.orderDate >= start && order.orderDate <= end;
      });

      this.noData = this.filteredOrders.length === 0;

      if (!this.noData) {
        this.aggregateProductData();
      }
    },
    aggregateProductData() {
      const productData = {};

      this.filteredOrders.forEach(order => {
        order.lines.forEach(line => {
          const productName = line.productName;
          const quantity = parseFloat(line.quantity);

          if (productData[productName]) {
            productData[productName] += quantity;
          } else {
            productData[productName] = quantity;
          }
        });
      });

      this.aggregatedProductData = productData;
    }
  }
};
</script>

<style scoped>
.v-data-table {
  margin-top: 20px;
}
</style>
