import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Toplam Satılan Ürünler")]),_c('p',[_vm._v("Her günün raporu bir sonraki gün 08:00'da oluşur.")]),_c(VRow,{staticStyle:{"align-items":"center"}},[_c(VCol,[_c(VTextField,{attrs:{"label":"Başlangıç Tarihi","type":"date","min":_vm.startDateLimit,"max":_vm.yesterdayDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Bitiş Tarihi","type":"date","min":_vm.startDateLimit,"max":_vm.yesterdayDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c(VCol,[_c(VBtn,{staticClass:"custom-btn",staticStyle:{"margin-left":"8px"},attrs:{"elevation":"2"},on:{"click":_vm.filterByDateRange}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Tarih Aralığını Uygula ")],1)],1)],1),(_vm.noData)?_c(VAlert,{staticClass:"mt-1",attrs:{"type":"info"}},[_vm._v(" Seçilen tarih aralığında kayıt bulunmamaktadır. ")]):_c(VDataTable,{staticClass:"mt-1",attrs:{"headers":_vm.headers,"items":_vm.sortedProductData,"item-key":"productName","items-per-page":100},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.productName))]),_c('td',[_vm._v(_vm._s(item.quantity))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }